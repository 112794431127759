import * as React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router';

export interface ProtectedRouteProps extends RouteProps {
    token?: string;
    authenticationPath: string;
    redirectPathOnAuthentication: string;
    setRedirectPathOnAuthentication: (path: string) => void;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
    const currentLocation = useLocation();

    let redirectPath = currentLocation.pathname;
    if (!props.token) {
        props.setRedirectPathOnAuthentication(currentLocation.pathname);
        redirectPath = props.authenticationPath;
    }

    if (redirectPath !== currentLocation.pathname) {
        const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
        return <Route {...props} component={renderComponent} render={undefined} />;
    } else {
        return <Route {...props} />;
    }
};

export default ProtectedRoute;