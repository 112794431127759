import { base_url } from '../ApiRequest';
import axios from 'axios';

class Logger {
  private buffer: any = [];
  private bufferLimit: any = 200;
  private sessionContext: any;
  private timeout: any = false;

  static instance: Logger;

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  public add(message: any, flush: any = false) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.buffer.push(new Date().toISOString() + ': ' + message);
    if (this.buffer.length && (flush || this.buffer.length >= this.bufferLimit)) {
      this.flush();
    }
    let that: any = this;
    this.timeout = setTimeout(function() {
      that.flush();
    }, 1000);
  }

  public flush() {
    if (window.navigator.onLine) {
      let buffer = this.buffer.splice(0, this.bufferLimit),
          session = JSON.parse(localStorage.getItem('session') as any);
      axios.post(base_url + '/app-logger.php', {
        rows: buffer,
        user: (session && 'user' in session ? session.user : false)
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).catch(() => {
        this.buffer.push(...buffer);
      });
    } else {
      let that: any = this;
      this.timeout = setTimeout(function() {
        that.flush();
      }, 3600);
    }
  }
}

export default Logger;