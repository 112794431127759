import Bugsnag from "@bugsnag/js";

class FileSystem {

    private minStorage = 512 * 1024 * 1024;
    private w: any;
    private fs: any;

    private requestAccess() {
        return new Promise((resolve: any, reject: any) => {
            this.w.navigator.PersistentStorage.queryUsageAndQuota((used: any, remaining: any) => {

                let prom = Promise.resolve(this.minStorage);
                if ((remaining - used) < 1024 || remaining < this.minStorage) {
                    prom = new Promise((_resolve: any, _reject: any) => {
                        this.w.navigator.PersistentStorage.requestQuota((remaining < this.minStorage ? this.minStorage : remaining + this.minStorage), (size: any) => {
                            _resolve(size);
                        }, (error: any) => {
                            _reject(error);
                        });
                    });
                }
                return prom.then((size: any) => {
                    this.w.requestFileSystem(this.w.PERSISTENT, size, (fs: any) => {
                        resolve(fs);
                    }, (e: any) => {
                        var msg = '';
                        switch (e.code) {
                            case this.w.FileError.QUOTA_EXCEEDED_ERR:
                                msg = 'QUOTA_EXCEEDED_ERR';
                                break;
                            case this.w.FileError.NOT_FOUND_ERR:
                                msg = 'NOT_FOUND_ERR';
                                break;
                            case this.w.FileError.SECURITY_ERR:
                                msg = 'SECURITY_ERR';
                                break;
                            case this.w.FileError.INVALID_MODIFICATION_ERR:
                                msg = 'INVALID_MODIFICATION_ERR';
                                break;
                            case this.w.FileError.INVALID_STATE_ERR:
                                msg = 'INVALID_STATE_ERR';
                                break;
                            default:
                                msg = 'Unknown Error';
                                break;
                        }
                        reject(msg);
                    });
                })
            }, (error: any) => {
                console.log(error);
                reject(error);
            });
        });
    }

    constructor(fs: any = undefined) {
        this.w = (window as any);
        this.w.requestFileSystem = this.w.requestFileSystem || this.w.webkitRequestFileSystem;
        this.w.navigator.PersistentStorage = this.w.navigator.PersistentStorage || this.w.navigator.webkitPersistentStorage;
        this.fs = fs;
        if (!fs) {
            this.requestAccess().then((fs: any) => {
                this.fs = fs;
            });
        }
    }

    init() {
        return new Promise((resolve: any, reject: any) => {
            this.requestAccess().then((fs: any) => {
                this.fs = fs;
                resolve(fs);
            }).catch(reject);
        });
    }

    hasAccess() {
        return !!this.fs;
    }

    removeFile(path: any) {

        return new Promise((resolve: any, reject: any) => {

            this.requestAccess().then((fs: any) => {

                fs.root.getFile(path, {
                    create: false
                }, (fileEntry: any) => {
                    fileEntry.remove(() => {
                        resolve();
                    });
                }, (error: any) => {
                    resolve();
                });

            });

        });

    }

    getEntry(filename: any) {
        return new Promise((resolve: any, reject: any) => {

            this.requestAccess().then((fs: any) => {
                fs.root.getFile(filename, {
                    create: false
                }, (fileEntry: any) => {
                    resolve(fileEntry);
                });
            }).catch((error: any) => {
                reject(error);
            });

        });
    }

    getAll(prefix: any = '') {
        return new Promise((resolve: any, reject: any) => {

            this.requestAccess().then((fs: any) => {

                const dirReader = fs.root.createReader();
                let files: any = [];

                function readEntries() {
                    dirReader.readEntries((results: any) => {
                        if (results.length) {
                            results.forEach((result: any) => {
                                if (!prefix || result.name.indexOf(prefix) === 0) {
                                    files.push(Promise.resolve(result.name));
                                }
                            });
                            readEntries();
                        } else {
                            Promise.all(files).then((result: any) => {
                                resolve(result);
                            }).catch((error: any) => {
                                reject(error);
                            });
                        }
                    }, (error: any) => {
                        reject(error);
                    });
                };

                readEntries();

            }).catch((error: any) => {
                reject(error);
            });

        });
    }

    get(filename: any, parseJson: boolean = true) {
        return new Promise((resolve: any, reject: any) => {
            this.requestAccess().then((fs: any) => {
                fs.root.getFile(filename, {
                    create: false
                }, (fileEntry: any) => {

                    fileEntry.file((file: any) => {
                        if (parseJson) {
                            const reader = new FileReader();
                            reader.onloadend = function () {

                                let body: any;

                                Bugsnag.leaveBreadcrumb('Parsing JSON', this.result as any);
                                try {
                                    body = JSON.parse(this.result as any);
                                    resolve(body);
                                } catch (e) {
                                    reject(new Error('Failed to parse JSON.'));
                                }
                            };
                            reader.onerror = function (error: any) {
                                reject(error);
                            };
                            reader.readAsText(file);
                        } else {
                            resolve(file);
                        }
                    }, (error: any) => {
                        reject(error);
                    });

                }, (error: any) => {
                    reject(error);
                });
            });
        });
    }

    download(filename: any) {
        return new Promise((resolve: any, reject: any) => {
            this.requestAccess().then((fs: any) => {
                fs.root.getFile(filename, {
                    create: false
                }, (fileEntry: any) => {

                    fileEntry.file((file: any) => {
                        const a = document.createElement('a');
                        const url = window.URL.createObjectURL(file);
                        document.body.appendChild(a);
                        a.href = url;
                        a.download = filename;
                        a.click();
                        setTimeout(() => {
                            window.URL.revokeObjectURL(url);
                            document.body.removeChild(a);
                        }, 0)
                    }, (error: any) => {
                        reject(error);
                    });

                }, (error: any) => {
                    reject(error);
                });
            });
        });
    }

    put(filename: any, content: any, encoding: any = 'application/json') {
        return new Promise((resolve: any, reject: any) => {
            this.requestAccess().then((fs: any) => {
                fs.root.getFile(filename, {
                    create: true
                }, (fileEntry: any) => {

                    fileEntry.createWriter((fileWriter: any) => {

                        fileWriter.onwriteend = () => {
                            if (fileWriter.length === 0) {
                                fileWriter.write(new Blob([(encoding === 'application/json' ? JSON.stringify(content) : content)], { type: encoding }));
                            } else {
                                resolve(content);
                            }
                        };
                        fileWriter.onerror = (e: any) => {
                            reject(e);
                        };
                        fileWriter.seek(0);
                        fileWriter.truncate(0);

                    }, (error: any) => {
                        reject(error);
                    });

                }, (error: any) => {
                    reject(error);
                });
            });
        });
    }

    putFile(filename: any, content: any, encoding: any = 'application/json') {
        return new Promise((resolve: any, reject: any) => {
            this.requestAccess().then((fs: any) => {
                fs.root.getFile(filename, {
                    create: true
                }, (fileEntry: any) => {

                    fileEntry.createWriter((fileWriter: any) => {

                        fileWriter.onwriteend = () => {
                            if (fileWriter.length === 0) {
                                fileWriter.write(content);
                            } else {
                                resolve(content);
                            }
                        };
                        fileWriter.onerror = (e: any) => {
                            reject(e);
                        };
                        fileWriter.seek(0);
                        fileWriter.truncate(0);

                    }, (error: any) => {
                        reject(error);
                    });

                }, (error: any) => {
                    reject(error);
                });
            });
        });
    }

}

export default FileSystem;