import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { SessionContextProvider, TasksContextProvider } from './Contexts';
import { BrowserRouter } from 'react-router-dom';
import App from './Containers/App';
import * as serviceWorker from './serviceWorker';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Logger from "./Components/Logger";
import moment from 'moment';
import FileSystem from './Components/FileSystem';

const metadata: any = require('./metadata.json');
const env: any = process.env.REACT_APP_ENV;
const nice_env: any = env.toUpperCase().substring(0, 1) + env.toLowerCase().substring(1);

const fileSystem = new FileSystem();

if (!(env in metadata)) {
    metadata[env] = {
        build: 1,
        nice_env,
        build_date: moment().format('DD/MM/YYYY HH:mm')
    }
}

let ErrorBoundary = function(props: any) {
    return (<div>{props.children}</div>);
}
if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'development') {
    Bugsnag.start({
        apiKey: 'e4210ba2e9b0043d206208f783a45799',
        plugins: [new BugsnagPluginReact()],
        appVersion: metadata[process.env.REACT_APP_ENV || 'development'].build.toString(),
        onError: function (event) {
            Logger.getInstance().add('Error - ' + JSON.stringify(event));
            fileSystem.put('error-' + Date.now(), JSON.stringify(event));
        }
    });

    ErrorBoundary = (Bugsnag.getPlugin('react') as any).createErrorBoundary(React);
}
Logger.getInstance().add('App Started');

ReactDOM.render(
    <ErrorBoundary>
        <React.StrictMode>
            <BrowserRouter>
                <SessionContextProvider>
                    <TasksContextProvider>
                        <App />
                    </TasksContextProvider>
                </SessionContextProvider>
            </BrowserRouter>
        </React.StrictMode>
    </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
