import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import Layout from '../../Components/Layout';
import { useHistory, useLocation } from "react-router-dom";
import { useTasksContext } from '../../Contexts/Tasks';
import './index.scss';

function Dashboard() {

    const [taskContext] = useTasksContext();
    const location = useLocation() as any;
    const history = useHistory();

    let diary: any = {}, scheduledAt;
    if (taskContext.tasks) {
        Object.keys(taskContext.tasks).forEach((i) => {
            if (taskContext.tasks[i] !== null && (!('isComplete' in taskContext.tasks[i]) || !taskContext.tasks[i].isComplete) && taskContext.tasks[i].status !== 'work_outstanding') {
                scheduledAt = moment(taskContext.tasks[i].scheduled_at, 'YYYY-MM-DD HH:mm');
                if (!(scheduledAt.format('DD/MM/YYYY') in diary)) {
                    diary[scheduledAt.format('DD/MM/YYYY')] = [];
                }
                diary[scheduledAt.format('DD/MM/YYYY')].push(taskContext.tasks[i]);
            }
        });
    }

    return (
        <Layout className="Dashboard">
            <div className="container">
                {location && location.state && 'alert' in location.state && (location.state as any).alert.message && (
                    <Alert variant={(location.state as any).alert.variant as any}>{(location.state as any).alert.message}</Alert>
                )}
                {taskContext.state !== 'idle' && (
                    <Alert variant="info">Loading...</Alert>
                )}
                {taskContext.maintenance && (
                    <Alert variant="danger"><strong>Under Maintenance</strong>: Any changes made to tasks whilst under maintenance will be saved on the app.</Alert>
                )}
                {taskContext.state === 'idle' && Object.keys(taskContext.tasks).length > 0 && (
                    <ul className="ScheduleDayList">
                        {diary && Object.keys(diary).map((day: any) => {
                            return (
                                <li key={`day-${day}`}>
                                    <strong className="d-block">{day}</strong>
                                    <ul className="ScheduleHourList">
                                        {Object.keys(diary[day]).map((idx) => {
                                            return (
                                                <li key={`time-${day}-${idx}`}>
                                                    <div className="d-flex">
                                                        <span className="task-detail">
                                                            <strong>{moment(diary[day][idx].scheduled_at, 'YYYY-MM-DD HH:mm').format('HH:mm')}</strong> - {diary[day][idx].title}<br/>
                                                            {diary[day][idx].property.address.property}, {diary[day][idx].property.address.estate}, {diary[day][idx].property.address.town}, {diary[day][idx].property.address.county}, {diary[day][idx].property.address.postcode}
                                                        </span>
                                                        <span className="ml-auto task-status">
                                                            <strong>Created:</strong> {moment(diary[day][idx].created_at, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm')}<br />
                                                            <strong>Status:</strong> {diary[day][idx].status_labels[diary[day][idx].status]}
                                                        </span>
                                                        <span className="ml-auto task-buttons">
                                                            {['issues_found', 'no_issues', 'complete', 'missed_appointment', 'parts_needed'].indexOf(diary[day][idx].status) > -1 && (
                                                                <Button variant="info">Pending</Button>
                                                            )}
                                                            {['issues_found', 'no_issues', 'complete', 'missed_appointment', 'parts_needed'].indexOf(diary[day][idx].status) === -1 && (
                                                                <Button variant="primary" onClick={() => { history.push(`/task/${diary[day][idx].id}`); }}>View Task</Button>
                                                            )}
                                                        </span>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </li>
                            );
                        })}
                    </ul>
                )}
                {taskContext.state === 'idle' && Object.keys(taskContext.tasks).length === 0 && (
                    <Alert variant="info">You do not currently have any jobs assigned.</Alert>
                )}
            </div>
        </Layout>
    );
}

export default Dashboard;