import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import Layout from '../../Components/Layout';
import { useLocation } from "react-router-dom";
import { useTasksContext } from '../../Contexts/Tasks';
import './index.scss';
import Logger from "../../Components/Logger";

function Queue() {

    const [taskContext, setTaskContext] = useTasksContext();
    const location = useLocation() as any;

    let diary: any = {}, c: any = 0, i, scheduledAt;
    if (taskContext.tasks) {
        Object.keys(taskContext.tasks).forEach((i) => {
            if (taskContext.tasks[i] !== null && 'isDirty' in taskContext.tasks[i]) {
                scheduledAt = moment(taskContext.tasks[i].scheduled_at, 'YYYY-MM-DD HH:mm');
                if (!(scheduledAt.format('DD/MM/YYYY') in diary)) {
                    diary[scheduledAt.format('DD/MM/YYYY')] = [];
                }
                diary[scheduledAt.format('DD/MM/YYYY')].push(taskContext.tasks[i]);
                c++;
            }
        });
    }

    const resend = (task_id: any) => {
        if (task_id in taskContext.tasks && (!('sent' in taskContext.tasks[task_id]) || window.confirm('This task has already been sent.\n\nResending could result in undesired effects.\n\nAre you sure?'))) {
            Logger.getInstance().add('Task resent: ' + JSON.stringify(taskContext.tasks[task_id]));
            delete taskContext.tasks[task_id].sent;
            delete taskContext.tasks[task_id].sent_at;
            setTaskContext({...taskContext, state: 'resend', task_id, tasks: taskContext.tasks});
        }
    }

    const remove = (task_id: any) => {
        if (window.confirm('Are you sure you want to remove this from the queue?')) {
            Logger.getInstance().add('Task removed after confirmed by user: ' + JSON.stringify(taskContext.tasks[task_id]));
            setTaskContext({...taskContext, state: 'remove', task_id});
        }
    }

    const clearSent = () => {
        if (window.confirm('Are you sure you want to remove ALL sent items from the queue?')) {
            let tasks: any = (taskContext.tasks ? {...taskContext.tasks} : {});
            Object.keys(taskContext.tasks).forEach((i) => {
                if ('sent' in tasks[i]) {
                    tasks[i].isDeleted = true;
                }
            });
            setTaskContext({...taskContext, tasks, state: 'sync', task_id: undefined});
        }
    }

    return (
        <Layout className="Queue">
            <div className="container">
                {location && location.state && 'alert' in location.state && (location.state as any).alert.message && (
                    <Alert variant={(location.state as any).alert.variant as any}>{(location.state as any).alert.message}</Alert>
                )}
                {taskContext.state !== 'idle' && (
                    <Alert variant="info">Loading...</Alert>
                )}
                {taskContext.maintenance && (
                    <Alert variant="danger"><strong>Under Maintenance</strong>: Any changes made to tasks whilst under maintenance will be saved on the app.</Alert>
                )}
                {taskContext.state === 'idle' && c > 0 && (
                    <>
                        <ul className="ScheduleDayList">
                            {diary && Object.keys(diary).map((day: any) => {
                                return (
                                    <li key={`day-${day}`}>
                                        <strong className="d-block">{day}</strong>
                                        <ul className="ScheduleHourList">
                                            {Object.keys(diary[day]).map((idx) => {
                                                console.log(diary[day][idx]);
                                                return (
                                                    <li key={`time-${day}-${idx}`}>
                                                        <div className="d-flex">
                                                            <span className="task-detail">
                                                                <strong>{moment(diary[day][idx].scheduled_at, 'YYYY-MM-DD HH:mm').format('HH:mm')}</strong> - {diary[day][idx].title}<br/>
                                                                {diary[day][idx].property.address.property}, {diary[day][idx].property.address.estate}, {diary[day][idx].property.address.town}, {diary[day][idx].property.address.county}, {diary[day][idx].property.address.postcode}
                                                            </span>
                                                            <span className="ml-auto task-status">
                                                                <strong>Created:</strong> {moment(diary[day][idx].created_at, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm')}<br />
                                                                <strong>Status:</strong> {'sent' in diary[day][idx] ? 'Sent' : 'Pending'}
                                                            </span>
                                                            <span className="ml-auto task-buttons">
                                                                {(!('sent' in diary[day][idx]) ) && (
                                                                    <Button variant="primary" onClick={() => resend(diary[day][idx].id)}>Resend</Button>
                                                                )}
                                                                <Button variant="danger" className="ml-1" onClick={() => remove(diary[day][idx].id)}><i className="fa fa-trash" /></Button>
                                                            </span>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="text-center mt-5">
                            <Button variant="danger" onClick={() => clearSent()}>Clear Sent Items</Button>
                        </div>
                    </>
                )}
                {taskContext.state === 'idle' && c === 0 && (
                    <Alert variant="info">You do not currently have any jobs assigned.</Alert>
                )}
            </div>
        </Layout>
    );
}

export default Queue;