import { createContext, useContext, useEffect, useState } from 'react';
import { useSessionContext } from '../Session';
import { ApiRequest, base_url } from '../../Components/ApiRequest';
import FileSystem from '../../Components/FileSystem';
import moment from 'moment';
import React from 'react';
import Bugsnag from '@bugsnag/js';
import Logger from "../../Components/Logger";
import axios from 'axios';

export interface Tasks {
    state: any;
    maintenance: any;
    error?: any;
    lastSyncronised?: any;
    tasks?: any;
    fs?: any;
    log?: any;
    task_id?: any;
}

export const initialTasks: Tasks = ({
    state: 'pending',
    maintenance: false
});

export const TasksContext = createContext<[Tasks, (tasks: Tasks) => void]>([initialTasks, () => {}]);
export const useTasksContext = () => useContext(TasksContext);

export const TasksContextProvider: React.FC = (props) => {
    const [tasksContext, setTasksContext] = useState(initialTasks);
    const defaultTasksContext: [Tasks, typeof setTasksContext]  = [tasksContext, setTasksContext];

    const [sessionContext, setSessionContext] = useSessionContext();
    const fileSystem = new FileSystem(tasksContext.fs);
    const apiRequest = new ApiRequest(sessionContext, setSessionContext);

    // When syncronising tasks, unsure we only overwrite certain fields.
    const mergeTask = (old_task: any, new_task: any) => {
        if ('isDirty' in old_task && old_task.isDirty) {
            old_task.title = new_task.title;
            old_task.description = new_task.description;
            old_task.property = new_task.property;
            return old_task;
        } else {
            return new_task;
        }
    };

    useEffect(() => {
        // Ensure we're logged in before we do anything.
        if (!sessionContext.token || !sessionContext.user) {
            return;
        }
        Logger.getInstance().add('State changed: ' + tasksContext.state);
        // Step 1: Initialise File System.
        if (tasksContext.state === 'pending') {
            Bugsnag.leaveBreadcrumb('Task Context State: Pending');
            fileSystem.init().then((fs: any) => {
                setTasksContext({ ...tasksContext, state: 'ready', fs });
            });
        // Step 2: Get existing information.
        } else if (tasksContext.state === 'ready') {
            Bugsnag.leaveBreadcrumb('Task Context State: Ready');
            fileSystem.get(sessionContext.user.id + '-tasks.json').then((tasks: any) => {
                tasks = Object.keys(tasks).reduce((acc: any, key: any) => {
                    if (key && tasks[key] !== null) {
                        acc[key] = tasks[key];
                    }
                    return acc;
                }, {});
                // setTasksContext({ ...tasksContext, state: 'convert', tasks });
                setTasksContext({ ...tasksContext, state: 'prune', tasks });
            }).catch(() => {
                // setTasksContext({ ...tasksContext, state: 'ready2' });
                setTasksContext({ ...tasksContext, state: 'sync' });
            });
        // Step 2: Get existing information.
        } else if (tasksContext.state === 'prune') {
            Bugsnag.leaveBreadcrumb('Task Context State: Prune');
            let cutoff = moment().subtract(7, 'days');
            let tasks = Object.keys(tasksContext.tasks).reduce((acc: any, key: any) => {
                let task = tasksContext.tasks[key];
                if (!task) {
                    return acc;
                }
                if ('sent' in task) {
                    // if no date, we'll set one now and it'll be deleted in a week.
                    if (!('sent_at' in task)) {
                        task.sent_at = moment().format('DD/MM/YYYY HH:mm');
                    }
                    if(moment(task.sent_at, 'DD/MM/YYYY HH:mm').isBefore(cutoff)) {
                        task.isDeleted = true;
                    }
                }
                acc[task.id] = task;
                return acc;
            }, {});
            setTasksContext({ ...tasksContext, state: 'save', tasks });
        // } else if (tasksContext.state === 'convert') {
        //     Bugsnag.leaveBreadcrumb('Task Context State: Convert');
        //     let promises: any = [];
        //     Object.keys(tasksContext.tasks).forEach((key: any) => {
        //         ((k) => {
        //             promises.push(new Promise((_resolve, _reject) => {
        //                 fileSystem.put(sessionContext.user.id + '-task-' + tasksContext.tasks[k].id + '.json', tasksContext.tasks[k]).then(() => {
        //                     _resolve(k);
        //                 }).catch((error: any) => {
        //                     _reject(error);
        //                 });
        //             }));
        //         })(key);
        //     });
        //     Promise.all(promises).then(() => {
        //         fileSystem.removeFile(sessionContext.user.id + '-tasks.json').then(() => {
        //             setTasksContext({ ...tasksContext, state: 'ready2' });
        //         }).catch(() => {
        //             setTasksContext({ ...tasksContext, state: 'ready2' });
        //         })
        //     }).catch((error: any) => {
        //         setTasksContext({ ...tasksContext, state: 'error', error });
        //     });
        // } else if (tasksContext.state === 'ready2') {
        //     Bugsnag.leaveBreadcrumb('Task Context State: Ready2');
        //     // Get all individual task files.
        //     fileSystem.getAll(sessionContext.user.id + '-task-').then((files: any) => {
        //         let promises: any = [];
        //         files.forEach((f: any) => {
        //             ((filename) => {
        //                 promises.push(new Promise((_resolve, _reject) => {
        //                     fileSystem.get(filename, false).then((file: any) => {
        //                         _resolve(file);
        //                     }).catch((e) => {
        //                         _reject(e);
        //                     });
        //                 }));
        //             })(f);
        //         });
        //         Promise.all(promises).then((tasks: any) => {
        //             // Merge them with the existing.
        //             tasks = tasks.reduce((acc: any, item: any)  => {
        //                 acc[item['id']] = item;
        //                 return acc;
        //             }, tasksContext.tasks ?? {});
        //             setTasksContext({ ...tasksContext, state: 'sync', tasks });
        //         }).catch(() => {
        //             setTasksContext({ ...tasksContext, state: 'sync' });
        //         })
        //     }).catch(() => {
        //         setTasksContext({ ...tasksContext, state: 'sync' });
        //     });
        } else if (tasksContext.state === 'error') {
            Bugsnag.leaveBreadcrumb('Task Context State: Error');
            Logger.getInstance().add('Error: ' + JSON.stringify(tasksContext.error));
            Bugsnag.notify(tasksContext.error);
        // Step 2.x: Resend
        } else if (tasksContext.state === 'resend') {
            if (tasksContext.task_id) {
                Bugsnag.leaveBreadcrumb('Task Context State: Resend');
                let tasks: any = (tasksContext.tasks ? {...tasksContext.tasks} : {}),
                    i: any;
                for (i in tasks) {
                    if (tasks[i].id === tasksContext.task_id) {
                        tasks[i].isDirty = true;
                    }
                }
                setTasksContext({...tasksContext, tasks, state: 'sync', task_id: undefined})
            } else {
                setTasksContext({ ...tasksContext, state: 'idle' });
            }
        // Step 2.x: Delete
        } else if (tasksContext.state === 'remove') {
            if (tasksContext.task_id) {
                Bugsnag.leaveBreadcrumb('Task Context State: Remove');
                let tasks: any = (tasksContext.tasks ? {...tasksContext.tasks} : {}),
                    i: any;
                for (i in tasks) {
                    if (tasks[i].id === tasksContext.task_id) {
                        tasks[i].isDeleted = true;
                    }
                }
                setTasksContext({...tasksContext, tasks, state: 'sync', task_id: undefined})
            } else {
                setTasksContext({ ...tasksContext, state: 'idle' });
            }
        // Step 2.1: Reset
        } else if (tasksContext.state === 'reset') {
            fileSystem.getAll().then((files: any) => {
                files.forEach((file: any) => {
                    fileSystem.removeFile(file);
                });
                setTasksContext({ ...tasksContext, state: 'ready', tasks: [] });
            }).catch(() => {
                setTasksContext({ ...tasksContext, state: 'ready', tasks: [] });
            });
        // Step 2.1: Reset Errors
        } else if (tasksContext.state === 'reset-errors') {
            fileSystem.getAll('error-').then((files: any) => {
                files.forEach((file: any) => {
                    fileSystem.removeFile(file);
                });
                setTasksContext({ ...tasksContext, state: 'ready', tasks: [] });
            }).catch(() => {
                setTasksContext({ ...tasksContext, state: 'ready', tasks: [] });
            });
        // Step 3: Synchronise.
        } else if (tasksContext.state === 'sync') {
            Bugsnag.leaveBreadcrumb('Task Context State: Sync');
            if (sessionContext.online) {
                // Get the latest list of tasks.
                apiRequest.get('/api/tasks').then((response: any) => {
                    if (response.request.responseURL.indexOf('suspended') > -1) {
                        setTasksContext({ ...tasksContext, state: 'idle', maintenance: true, tasks: (tasksContext.tasks ? tasksContext.tasks : {}) });
                        return;
                    }
                    //
                    let tasks: any = (tasksContext.tasks ? tasksContext.tasks : {}),
                        newTasks: any = {},
                        promises: any = [],
                        i: any;

                    response.data.data.forEach((task: any) => {
                        newTasks[task.id] = task;
                    });
                    for (i in tasks) {
                        if (!tasks.hasOwnProperty(i) || tasks[i] === null || tasks[i] === undefined) {
                            continue;
                        }
                        // Only delete if not dirty.
                        if ('isDeleted' in tasks[i]) {
                            // no longer exists.
                            fileSystem.getAll(i + '-').then((files: any) => {
                                files.forEach((file: any) => {
                                    fileSystem.removeFile(file);
                                });
                                fileSystem.removeFile(sessionContext.user.id + '-task-' + i + '-state.json').then(() => {}).catch(() => {});
                            });
                            delete tasks[i];
                            continue;
                        }
                        if ('isDirty' in tasks[i] && tasks[i].isDirty && !('sent_at' in tasks[i]) && !('sent' in tasks[i])) {
                        //if ('isComplete' in tasks[i] && tasks[i].isComplete) {
                            // eslint-disable-next-line
                            ((task: any) => {
                                fileSystem.getAll(i + '-').then((files: any) => {
                                    files.forEach((f: any) => {
                                        ((filename) => {
                                            promises.push(new Promise((_resolve, _reject) => {
                                                fileSystem.get(filename, false).then((file: any) => {
                                                    var fd = new FormData();
                                                    fd.append('file', file);
                                                    apiRequest.upload('/api/comment-attachment', fd).then((r: any) => {
                                                        _resolve(true);
                                                    }).catch((e) => {
                                                        _reject(e);
                                                    });
                                                }).catch((e) => {
                                                    _reject(e);
                                                });
                                            }));
                                        })(f);
                                    });
                                });
                                promises.push(new Promise((resolve: any, reject: any) => {
                                    apiRequest.patch('/api/tasks/' + i, tasks[i]).then((response: any) => {
                                        if (response.status === 202) {
                                            tasks[i].isDirty = false;
                                            tasks[i].sent = true;
                                            tasks[i].sent_at = moment().format('DD/MM/YYYY HH:mm');
                                            // tasks[i].isComplete = true;
                                            resolve(tasks[i]);
                                        } else {
                                            Bugsnag.addMetadata('response', response.data);
                                            Bugsnag.notify(new Error('Unexpected HTTP response: ' + response.status));
                                            resolve(tasks[i]);
                                        }
                                    }).catch((e) => {
                                        Bugsnag.addMetadata('response', e);
                                        Bugsnag.notify(e);
                                        resolve(tasks[i]);
                                    });
                                }));
                            })(tasks[i]);
                        }
                        if (!(i in newTasks)) {
                            tasks[i].sent = true;
                            tasks[i].isComplete = true;
                            tasks[i].sent_at = moment().format('DD/MM/YYYY HH:mm');
                            continue;
                        }
                    }
                    for (i in newTasks) {
                        if (!newTasks.hasOwnProperty(i)) {
                            continue;
                        }
                        if (i in tasks) {
                            tasks[i] = mergeTask(tasks[i], newTasks[i]);
                        } else {
                            tasks[i] = newTasks[i];
                        }
                    }
                    Promise.all(promises).then((updated: any) => {
                        for (i in updated) {
                            if (!updated.hasOwnProperty(i) || updated[i] === true) {
                                continue;
                            }
                            tasks[updated[i].id] = updated[i];
                        }
                        setTasksContext({ ...tasksContext, lastSyncronised: moment().format('YYYY-MM-DD HH:mm'), maintenance: false, state: 'save-no-sync', tasks });
                    }).catch((error: any) => {
                        setTasksContext({ ...tasksContext, state: 'error', error });
                    });
                }).catch((error: any) => {
                    setTasksContext({ ...tasksContext, state: 'error', error });
                });
            } else {
                setTasksContext({ ...tasksContext, state: 'idle' });
            }
        } else if (tasksContext.state === 'save') {
            Bugsnag.leaveBreadcrumb('Task Context State: Save');
            let tasks = Object.keys(tasksContext.tasks).reduce((acc: any, key: any) => {
                if (key && tasksContext.tasks[key] !== null) {
                    acc[key] = tasksContext.tasks[key];
                }
                return acc;
            }, {});
            tasksContext.tasks = tasks;
            fileSystem.put(sessionContext.user.id + '-tasks.json', tasks).then(() => {
                setTasksContext({ ...tasksContext, state: 'sync' });
            }).catch(() => {
                setTasksContext({ ...tasksContext, state: 'sync' });
            });
        } else if (tasksContext.state === 'inject') {
            Bugsnag.leaveBreadcrumb('Task Context State: Inject');
            fileSystem.put(sessionContext.user.id + '-tasks.json', tasksContext.tasks).then(() => {
                setTasksContext({ ...tasksContext, state: 'ready' });
            }).catch(() => {
                setTasksContext({ ...tasksContext, state: 'ready' });
            });
        } else if (tasksContext.state === 'save-no-sync') {
            Bugsnag.leaveBreadcrumb('Task Context State: Save (No Sync)');
            fileSystem.put(sessionContext.user.id + '-tasks.json', tasksContext.tasks).then(() => {
                setTasksContext({ ...tasksContext, state: 'idle' });
            }).catch(() => {
                setTasksContext({ ...tasksContext, state: 'idle' });
            });
        } else if (tasksContext.state === 'debug') {
            Bugsnag.leaveBreadcrumb('Task Context State: Debug');
            fileSystem.getAll(false).then((files: any) => {
                let promises: any = [];
                files.forEach((f: any) => {
                    ((filename) => {
                        promises.push(new Promise((_resolve, _reject) => {
                            fileSystem.get(filename, false).then((file: any) => {
                                var fd = new FormData();
                                fd.append('file', file);
                                apiRequest.upload('/api/debug2', fd).then((r: any) => {
                                    _resolve(r);
                                }).catch((e) => {
                                    _reject(e);
                                });
                            }).catch((e) => {
                                _reject(e);
                            });
                        }));
                    })(f);
                });
                Promise.all(promises).then(() => {
                    setTasksContext({ ...tasksContext, state: 'idle' });
                });
            }).catch(() => {
                setTasksContext({ ...tasksContext, state: 'idle' });
            })
            // apiRequest.post('/api/debug', {
            //     tasks: base64.encode(JSON.stringify(tasksContext.tasks)),
            //     user: sessionContext.user
            // }).then((response: any) => {
            //     setTasksContext({ ...tasksContext, state: 'idle' });
            // }).catch(() => {
            //     setTasksContext({ ...tasksContext, state: 'idle' });
            // });
        }
        // do something
    }, [fileSystem, apiRequest, tasksContext, sessionContext, setSessionContext]);

    return (
        <TasksContext.Provider value={defaultTasksContext}>
            {props.children}
        </TasksContext.Provider>
    );
}