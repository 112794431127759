import React from 'react';
import { Switch, Route } from 'react-router';
import ProtectedRoute, { ProtectedRouteProps } from '../../Components/ProtectedRoute';
import { useSessionContext } from '../../Contexts/Session';

import Login from '../../Containers/Auth/Login';
import Dashboard from '../../Containers/Dashboard';
import TaskV2 from '../../Containers/TaskV2';
import Queue from '../../Containers/Queue';
import Debug from '../../Containers/Debug';

const App: React.FC = () => {
  const [sessionContext, setSessionContext] = useSessionContext();

  const setRedirectPathOnAuthentication = (path: string) => {
    setSessionContext({...sessionContext, redirectPathOnAuthentication: path});
  }

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    token: sessionContext.token,
    authenticationPath: '/login',
    redirectPathOnAuthentication: sessionContext.redirectPathOnAuthentication || '/',
    setRedirectPathOnAuthentication
  };

  return (
    <Switch>
        <ProtectedRoute {...defaultProtectedRouteProps} exact={true} path='/' component={Dashboard} />
        <ProtectedRoute {...defaultProtectedRouteProps} exact={true} path='/task/:id' component={TaskV2} />
        <ProtectedRoute {...defaultProtectedRouteProps} exact={true} path='/queue' component={Queue} />
        <Route path='/login' component={Login} />
        <Route path='/debug' component={Debug} />
    </Switch>
  );
}

export default App;