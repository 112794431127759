import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import Layout from '../../Components/Layout';
import { useLocation } from "react-router-dom";
import './index.scss';
import FileSystem from '../../Components/FileSystem';
import { useSessionContext } from '../../Contexts';
import { ApiRequest, base_url } from '../../Components/ApiRequest';

function Debug() {

    const location = useLocation() as any;
    const [state, setState] = useState({
        files: [] as any,
        loading: true
    });
    const fileSystem = new FileSystem();

    const [sessionContext, setSessionContext] = useSessionContext();
    const apiRequest = new ApiRequest(sessionContext, setSessionContext);

    useEffect(() => {
        refreshFiles();
    }, []);

    const refreshFiles = () => {
        fileSystem.getAll().then((files: any) => {
            let promises: any = [];
            files.forEach((file: any) => {
                ((f) => {
                    promises.push(new Promise((res: any, rej: any) => {
                        fileSystem.getEntry(f).then((entry: any) => {
                            entry.getMetadata((meta: any) => {
                                entry.size = meta.size;
                                entry.modificationTime = meta.modificationTime;
                                res(entry);
                            });
                        }).catch((e: any) => { 
                            rej(e); 
                        });
                    }));
                })(file);
            });
            Promise.all(promises).then((files: any) => {
                setState({...state, files, loading: false});
            });
        });
    }

    const humanFileSize = (size: any) => {
        var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i) as any).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    const deleteEntry = (filename: any) => {
        fileSystem.removeFile(filename).then(() => {
            refreshFiles();
        });
    }
    const downloadEntry = (filename: any) => {
        fileSystem.download(filename);
    }

    const sendEntry = (filename: any) => {
        if (window.confirm('Are you sure you want to send this file?')) {
            fileSystem.get(filename, false).then((file: any) => {
                var fd = new FormData();
                fd.append('file', file);
                apiRequest.upload('/api/debug2', fd).then((r: any) => {
                    alert('File successfully sent.');
                }).catch((e) => {
                    alert('There was an error sending this file.');                
                });
            }).catch((e) => {
                alert('There was an error sending this file.');
            });
        }
    }

    return (
        <div className="Auth Debug">
            <h1>Debug</h1>
            <table className="table">
                <thead>
                    <tr>
                        <td>Filename</td>
                        <td>Size</td>
                        <td>Date</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <tbody>
                    {state.loading && (
                        <tr>
                            <td colSpan={4}>Loading files</td>
                        </tr>
                    )}
                    {!state.loading && (!state.files || state.files.length === 0) && (
                        <tr>
                            <td colSpan={4}>No files</td>
                        </tr>
                    )}
                    {state.files.map((f: any, idx: any) => {
                        return <tr key={idx}>
                            <td>{f.name}</td>
                            <td>{humanFileSize(f.size)}</td>
                            <td>{moment(f.modificationTime).format('DD/MM/YYYY h:mm:ss a')}</td>
                            <td>
                                <button type="button" onClick={(e) => downloadEntry(f.name)} className="btn btn-secondary mr-1">Download</button>
                                <button type="button" onClick={(e) => sendEntry(f.name)} className="btn btn-secondary mr-1">Send</button>
                                <button type="button" onClick={(e) => deleteEntry(f.name)} className="btn btn-danger">Delete</button>
                            </td>
                        </tr>;
                    })}
                </tbody>
            </table>
            <a href="/" className="btn btn-block btn-outline-primary">&lt; Back</a>
        </div>
    );
}

export default Debug;