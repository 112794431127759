import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import {useSessionContext} from '../../../Contexts/Session';
import ApiRequest from '../../../Components/ApiRequest';
import {useHistory} from 'react-router-dom';
import {Formik} from 'formik';
import * as yup from 'yup';
import './index.scss';

import logo from '../../../Assets/Images/logo.svg';
import Logger from "../../../Components/Logger";

const client_id = (process.env.REACT_APP_CLIENT_ID || 1);
const client_secret = (process.env.REACT_APP_CLIENT_SECRET || 'MhWzgEaSMc9lNivNvsmt4XyTidYPDQsEbN2xfmKA');

function Login() {

    // Get the current session context.
    const [sessionContext, setSessionContext] = useSessionContext();
    // Get the history object so we can change locations.
    const history = useHistory();

    const metadata = sessionContext?.metadata[process.env.REACT_APP_ENV || 'development'];

    // Define the form validation schema.
    const formSchema = yup.object({
        username: yup.string().email('Invalid email address').required('Email address is required'),
        password: yup.string().required('Password is required')
    });

    // Define the default form state.
    const [formState, setFormState] = useState({
        busy: false,
        error: ''
    });

    // Handle the validated form submit.
    const onSubmit = (e: any) => {

        // Prevent multiple attempts.
        if (formState.busy) {
            return;
        }

        // Let everyone know we're doing something here.
        setFormState({...formState, error: '', busy: true});

        // Submit the login request.
        new ApiRequest(sessionContext, setSessionContext).post('/oauth/token', {
            'grant_type': 'password',
            'client_id': client_id,
            'client_secret': client_secret,
            'username': e.username,
            'password': e.password,
            'scope': 'Maintenance'
        }).then((response: any) => {
            // Update the session with our authenticated token.
            setSessionContext({...sessionContext, token: response.data.access_token, expires: response.data.expires_in});
            Logger.getInstance().add('Logged in.', true);
            // Redirect back to the home page.
            history.push('/');
        }).catch((err: any) => {
            // Let everyone know there was an error.
            setFormState({...formState, error: 'Invalid email address and/or password', busy: false});
        });
    };

    // Render the form.
    return (
        <div className="Auth Login">

            <img src={logo} alt=""/>

            {formState.error && (
                <Alert variant="danger">{formState.error}</Alert>
            )}

            <Formik initialValues={{
                username: '',
                password: ''
            }} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="loginUsername">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                name="username"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.username}
                                isInvalid={!!errors.username}
                                isValid={touched.username && !errors.username}
                            />
                            <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="loginPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                isInvalid={!!errors.password}
                                isValid={touched.password && !errors.password}
                            />
                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </Form.Group>
                        <div className="LoginButtons">
                            <Button disabled={formState.busy} variant="primary" type="submit">
                                {formState.busy ? 'Checking' : 'Login'}
                            </Button>
                            {metadata && (
                                <div className="AppVersion">{metadata.nice_env} Build: {metadata.build}<br />{metadata.build_date}</div>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default Login;