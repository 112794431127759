import React, { useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import logo from '../../Assets/Images/logo.svg';
import {useSessionContext, useTasksContext} from "../../Contexts";
import Logger from '../Logger';
import './index.scss';

const Layout = (props: any) => {

    const [sessionContext, setSessionContext] = useSessionContext();
    const [tasksContext, setTaskContext] = useTasksContext();
    const [injectState, setInjectState] = useState({
        open: false,
        content: ''
    });
    const history = useHistory();
    const metadata = sessionContext?.metadata[process.env.REACT_APP_ENV || 'development'];

    const logout = (e: any) => {
        e.preventDefault();
        Logger.getInstance().add((sessionContext.user ? sessionContext.user.name : 'N/A') + ' logged out.');
        setSessionContext({...sessionContext, token: '', user: undefined});
        setTaskContext({...tasksContext, tasks: undefined, state: 'pending'})
        history.push('/');
    }

    const sendDebug = (e: any) => {
        e.preventDefault();
        if (window.confirm('Send debugging information to TVW for support?')) {
            Logger.getInstance().add('Debug sent.', true);
            setTaskContext({...tasksContext, state: 'debug'})
        } else if ((process.env.REACT_APP_ENV || 'development') === 'development' && window.confirm('Inject Content?')) {
            // let content = prompt('Inject content?');
            // if (content) {
            //     content = JSON.parse(content);
            //     setTaskContext({...tasksContext, tasks: content, state: 'inject'});
            // }
            setInjectState({...injectState, open: true});
        } else if (window.confirm('View debug data?')) {
            history.push('/debug');
        } else if (window.confirm('Reset Device?')) {
            setTaskContext({...tasksContext, state: 'reset'})
        } else if (window.confirm('Remove Errors?')) {
            setTaskContext({...tasksContext, state: 'reset-errors'})
        }
    }

    const doInjection = (e: any) => {
        setTaskContext({...tasksContext, tasks: JSON.parse(atob(injectState.content)), state: 'inject'});
        setInjectState({...injectState, open: false, content: ''});
    }

    return (
        <div className={`Layout ${props.className}`}>
            <header className="LayoutHeader">

                <Link to="/" id="header-logo"><img src={logo} className="img-fluid" alt=""/></Link>

                <div id="header-actions">

                    <div id="header-status" onClick={() => setTaskContext({...tasksContext, state: 'sync'})}>
                        {tasksContext.state === 'pending' && (
                            <>Pending <i className="fa fa-fw fa-lock"></i></>
                        )}
                        {tasksContext.state === 'ready' && (
                            <>Ready <i className="fa fa-fw fa-file"></i></>
                        )}
                        {tasksContext.state === 'sync' && (
                            <>Syncronising <i className="fa fa-fw fa-circle-o-notch fa-spin"></i></>
                        )}
                        {tasksContext.state === 'idle' && !sessionContext.online && (
                            <>
                                No Internet
                                <span className="fa-stack fa-fw">
                                    <i className="fa fa-signal fa-stack-1x"></i>
                                    <i className="fa fa-ban fa-stack-2x text-danger"></i>
                                </span>
                            </>
                        )}
                        {tasksContext.state === 'idle' && sessionContext.online && (
                            <>
                                Idle
                                <i className="fa fa-fw fa-signal"></i>
                            </>
                        )}
                    </div>

                    <div id="header-salutation">

                        Hi {sessionContext.user ? sessionContext.user.forename : 'there'} <span className="sep">|</span> <Link to="/" onClick={logout}>Logout</Link>

                    </div>

                </div>

            </header>

            <div className="LayoutBody">

                <aside className="LayoutNav">
                    <ul>
                        <li className={props.className === 'Dashboard' ? 'active' : ''}><Link to="/"><i className="fa fa-fw fa-home nav-icon"></i> Home</Link></li>
                        <li className={props.className === 'Queue' ? 'active' : ''}><Link to="/queue"><i className="fa fa-fw fa-list nav-icon"></i> Queue</Link></li>
                    </ul>
                    {metadata && (
                        <div className="AppVersion" onClick={sendDebug}>{metadata.nice_env} Build: {metadata.build}<br />{metadata.build_date}</div>
                    )}
                </aside>

                <div className="LayoutBodyContent">
                    {injectState.open && (
                        <>
                            <textarea placeholder="Copy/Paste" onChange={(e) => setInjectState({...injectState, content: e.target.value})} value={injectState.content}></textarea>
                            <button onClick={doInjection}>Update</button>
                        </>
                    )}
                    {props.children}
                </div>
            </div>

            <footer className="LayoutFooter">

                <p>10 Teal Farm Way, Teal Farm Park Pattinson Industrial Estate, Washington NE38 8BG</p>
                <p><strong>Tel:</strong> <a href="tel:00441915946666">0191 594 6666</a> <strong>Email:</strong> <a
                    href="mailto:info@hellensresidential.co.uk">info@hellensresidential.co.uk</a></p>

            </footer>

        </div>
    );
}

export default Layout;