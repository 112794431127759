import axios from 'axios';
import {useHistory} from 'react-router-dom';
import Bugsnag from "@bugsnag/js";
import FileSystem from '../FileSystem';
import { useTasksContext } from "../../Contexts";

export const base_url = (process.env.REACT_APP_BASE_URL || 'http://hellens-intranet.test');

export class ApiRequest {

    private sessionContext: any;
    private setSessionContext: any;

    constructor(sessionContext: any, setSessionContext: any) {
        this.sessionContext = sessionContext;
        this.setSessionContext = setSessionContext;

        axios.interceptors.response.use(function (response) {
            return response
        }, function (error) {
            Bugsnag.notify(error);
            if ('response' in error && error.response && 'status' in error.response && error.response.status === 401) {
                setSessionContext({...sessionContext, token: ''});
                const history = useHistory();
                history.push('/');
            }
            return Promise.reject(error)
        });
    }

    addHeaders(headers: any = {}) {
        if (this.sessionContext && this.sessionContext.token) {
            headers['Accept'] = 'application/json';
            headers['Authorization'] = `Bearer ${this.sessionContext.token}`;
            headers['X-Authorization'] = `Bearer ${this.sessionContext.token}`;
        }
        if (!('Content-Type' in headers)) {
            headers['Content-Type'] = 'application/json';
        }
        return headers;
    }

    prepUrl(url: any) {
        if (url.indexOf('http') !== 0) {
            url = base_url + url;
        }
        return url;
    }

    get(url: any) {
        return axios.get(this.prepUrl(url), {
            headers: this.addHeaders()
        });
    }

    handleAttachments(fd: any, data: any) {
        const fileSystem = new FileSystem();
        let promises: any = [];
        if ('attachments' in data && data.attachments && Array.isArray(data.attachments) && data.attachments.length) {
            data.attachments.forEach((attachment: any) => {
                ((filename: any) => {
                    promises.push(new Promise((resolve: any) => {
                        if (filename && filename.indexOf('@@@') === -1) {
                            fileSystem.get(filename, false).then((file: any) => {
                                resolve(file);
                            }).catch((e) => {
                                console.error(e);
                                resolve(undefined);
                            });
                        } else {
                            resolve(undefined);
                        }
                    }));
                })(attachment);
            });
        }
        if ('comments' in data && data.comments.length) {
            data.comments.forEach((comment: any) => {
                if ('attachments' in comment && comment.attachments && Array.isArray(comment.attachments) && comment.attachments.length) {
                    comment.attachments.forEach((attachment: any) => {
                        ((filename: any) => {
                            promises.push(new Promise((resolve: any) => {
                                if (filename && filename.indexOf('@@@') === -1) {
                                    fileSystem.get(filename, false).then((file: any) => {
                                        resolve(file);
                                    }).catch((e) => {
                                        console.error(e);
                                        resolve(undefined);
                                    });
                                } else {
                                    resolve(undefined);
                                }
                            }));
                        })(attachment);
                    });
                }
            });
        }
        if (data.workflow === 'key_handover_2') {
            if (data.payload.electricity_meter_image) {
                ((filename: any) => {
                    promises.push(new Promise((resolve: any) => {
                        if (filename && filename.indexOf('@@@') === -1) {
                            fileSystem.get(filename, false).then((file: any) => {
                                resolve(file);
                            }).catch((e) => {
                                console.error(e);
                                resolve(undefined);
                            });
                        } else {
                            resolve(undefined);
                        }
                    }));
                })(data.payload.electricity_meter_image);
            }
            if (data.payload.gas_meter_image) {
                ((filename: any) => {
                    promises.push(new Promise((resolve: any) => {
                        if (filename && filename.indexOf('@@@') === -1) {
                            fileSystem.get(filename, false).then((file: any) => {
                                resolve(file);
                            }).catch((e) => {
                                console.error(e);
                                resolve(undefined);
                            });
                        } else {
                            resolve(undefined);
                        }
                    }));
                })(data.payload.gas_meter_image);
            }
        }
        if ('payload' in data && data.payload !== null && '1' in data.payload) {
            Object.values(data.payload).forEach((item: any) => {
                if ('attachments' in item && item.attachments && Array.isArray(item.attachments) && item.attachments.length) {
                    item.attachments.forEach((attachment: any) => {
                        ((filename: any) => {
                            promises.push(new Promise((resolve: any) => {
                                if (filename && filename.indexOf('@@@') === -1) {
                                    fileSystem.get(filename, false).then((file: any) => {
                                        resolve(file);
                                    }).catch((e) => {
                                        console.error(e);
                                        resolve(undefined);
                                    });
                                } else {
                                    resolve(undefined);
                                }
                            }));
                        })(attachment);
                    });
                }
                if ('maintenance_issues' in item && item.maintenance_issues && Object.keys(item.maintenance_issues).length) {
                    Object.keys(item.maintenance_issues).forEach((key: any) => {
                        let issue = item.maintenance_issues[key];
                        issue.attachments.split('|||').forEach((attachment: any) => {
                            ((filename: any) => {
                                promises.push(new Promise((resolve: any) => {
                                    if (filename && filename.indexOf('@@@') === -1) {
                                        fileSystem.get(filename, false).then((file: any) => {
                                            resolve(file);
                                        }).catch((e) => {
                                            console.error(e);
                                            resolve(undefined);
                                        });
                                    } else {
                                        resolve(undefined);
                                    }
                                }));
                            })(attachment);
                        });
                    });
                }
            });
        }
        return Promise.all(promises).then((files: any) => {
            if (files && files.length) {
                files.forEach((file: any, idx: any) => {
                    if (file) {
                        fd.append('attachment_' + idx, file);
                    }
                });
            }
            return Promise.resolve(fd);
        }).catch((e: any) => {
            console.log(e);
            return Promise.reject(e);
        });
    }

    post(url: any, data: any) {
        let fd: FormData = new FormData();
        fd.append('body', JSON.stringify(data));
        return this.handleAttachments(fd, data).then((fd: any) => {
            return axios.post(this.prepUrl(url), fd, {
                headers: this.addHeaders({
                    'Content-Type': 'multipart/form-data'
                })
            });
        });
    }

    upload(url: any, fd: any) {
        return axios.post(this.prepUrl(url), fd, {
            headers: this.addHeaders({
                'Content-Type': 'multipart/form-data'
            })
        });
    }

    patch(url: any, data: any) {
        Bugsnag.leaveBreadcrumb('Patch', {data});
        let fd: FormData = new FormData();
        fd.append('body', JSON.stringify(data));
        fd.append('_method', 'patch');
        return new Promise((resolve: any, reject: any) => {
            this.handleAttachments(fd, data).then((fd: any) => {
                axios.post(this.prepUrl(url), fd, {
                    headers: this.addHeaders({
                        'Content-Type': 'multipart/form-data'
                    })
                }).then((resp: any) => {
                    resolve(resp);
                }).catch((e: any) => {
                    console.error({url, data, e});
                    reject(e);
                });
            });
        });
    }

}

export default ApiRequest;