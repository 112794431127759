import React from 'react';
import Layout from '../../Components/Layout';
import { useTasksContext } from "../../Contexts/Tasks";
import { useHistory, useParams } from "react-router";
import HomeVisit from "../../Components/TaskV2/HomeVisit";
import ExitHandover from "../../Components/TaskV2/ExitHandover";
import KeyHandover2 from "../../Components/TaskV2/KeyHandover2";
import VoidPropertyVisit from "../../Components/TaskV2/VoidPropertyVisit";
import PropertyInspection from "../../Components/TaskV2/PropertyInspection";
import RepairMaintenance from "../../Components/TaskV2/RepairMaintenance";
import FollowUp from "../../Components/TaskV2/FollowUp";
import InternalRepairMaintenance from "../../Components/TaskV2/InternalRepairMaintenance";
import './index.scss';

function Task() {

    const [taskContext] = useTasksContext();
    const history = useHistory();
    const { id } = useParams();

    if (!taskContext.tasks) {
        return null;
    }

    if (!(id in taskContext.tasks)) {
        history.push('/', {
            alert: {
                variant: 'danger',
                message: 'The requested task does not exist. Please select one from the list.'
            }
        });
        return null;
    }

    return (
        <Layout className="Task">
            {taskContext.tasks[id].workflow === 'home_visit' && (
                <HomeVisit id={id} task={taskContext.tasks[id]} />
            )}
            {taskContext.tasks[id].workflow === 'exit_handover' && (
                <ExitHandover id={id} task={taskContext.tasks[id]} />
            )}
            {taskContext.tasks[id].workflow === 'key_handover_2' && (
                <KeyHandover2 id={id} task={taskContext.tasks[id]} />
            )}
            {taskContext.tasks[id].workflow === 'void_property_visit' && (
                <VoidPropertyVisit id={id} task={taskContext.tasks[id]} />
            )}
            {taskContext.tasks[id].workflow === 'inspect_property' && (
                <PropertyInspection id={id} task={taskContext.tasks[id]} />
            )}
            {taskContext.tasks[id].workflow === 'repair_maintenance' && (
                <RepairMaintenance id={id} task={taskContext.tasks[id]} />
            )}
            {taskContext.tasks[id].workflow === 'internal_repair_maintenance' && (
                <InternalRepairMaintenance id={id} task={taskContext.tasks[id]} />
            )}
            {taskContext.tasks[id].workflow === 'follow_up' && (
                <FollowUp id={id} task={taskContext.tasks[id]} />
            )}
        </Layout>
    );
}

export default Task;