import {createContext, useContext, useEffect, useState} from 'react';
import ApiRequest from '../../Components/ApiRequest';
import React from 'react';
import moment from 'moment';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Logger from "../../Components/Logger";

export interface Session {
    token?: string;
    expires?: any;
    user?: any;
    online: any;
    alert?: any;
    redirectPathOnAuthentication?: string;
    metadata: any;
}

const localState = JSON.parse(localStorage.getItem('session') as string) || {};
localState.online = window.navigator.onLine;

export const initialSession: Session = (localState || {});
export const SessionContext = createContext<[Session, (session: Session) => void]>([initialSession, () => {}]);
export const useSessionContext = () => useContext(SessionContext);

export const SessionContextProvider: React.FC = (props) => {
    const [sessionContext, setSessionContext] = useState(initialSession);
    const defaultSessionContext: [Session, typeof setSessionContext]  = [sessionContext, setSessionContext];

    const metadata: any = require('../../metadata.json');
    const env: any = process.env.REACT_APP_ENV;
    const nice_env: any = env.toUpperCase().substring(0, 1) + env.toLowerCase().substring(1);

    if (!(env in metadata)) {
        metadata[env] = {
            build: 1,
            nice_env,
            build_date: moment().format('DD/MM/YYYY HH:mm')
        }
    }

    Bugsnag.start({
        apiKey: 'e4210ba2e9b0043d206208f783a45799',
        plugins: [new BugsnagPluginReact()],
        appVersion: metadata[process.env.REACT_APP_ENV || 'development'].build.toString(),
        onError: function (event) {
            Logger.getInstance().add('Error - ' + JSON.stringify(event));
        }
    });

    sessionContext.metadata = metadata;

    useEffect(() => {

        const handleConnectionChange = () => {
            Logger.getInstance().add('Connection change: ' + (window.navigator.onLine ? 'online' : 'offline'));
            setSessionContext({ ...sessionContext, online: window.navigator.onLine });
        };

        window.addEventListener('online', handleConnectionChange);
        window.addEventListener('offline', handleConnectionChange);

        localStorage.setItem('session', JSON.stringify(sessionContext));
        if (sessionContext.token && sessionContext.user === undefined) {
            // Get the current user.
            new ApiRequest(sessionContext, setSessionContext).get('/api/user').then((response: any) => {
                setSessionContext({ ...sessionContext, user: response.data.data });
                Bugsnag.setUser(response.data.data.id, response.data.data.email, response.data.data.name);
            }).catch((err: any) => {
                console.error(err);
            });
        }
        return () => {
            window.removeEventListener('online', handleConnectionChange);
            window.removeEventListener('offline', handleConnectionChange);
        }
    }, [sessionContext]);

    return (
        <SessionContext.Provider value={defaultSessionContext}>
            {props.children}
        </SessionContext.Provider>
    );
}